// modified from https://mui.com/material-ui/react-table/#sorting-amp-selecting
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useVisibilityChange } from "@uidotdev/usehooks";
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from '@mui/icons-material';

import { callApi, formatFieldName } from 'utils';

const defaultStyle = Object.freeze({
  minWidth: 650,
  '& th,td': {
    padding: 1,
    whiteSpace: 'pre-line',
  }
});

const defaultFormat = (row, col) => row[col];

function getComparator(orderBy) {
  let rev = 1;
  if (orderBy[0] === '-') {
    rev = -1;
    orderBy = orderBy.substring(1);
  }
  return (a, b) => rev * (a[orderBy] - b[orderBy]);
}

function EnhancedTableHead({columns, multiSelect, onSelectAllClick, selected, orderBy, onRequestSort}) {
  let orderCol, order;
  if (orderBy) {
    if (orderBy[0] === '-') {
      order = 'desc';
      orderCol = orderBy.substring(1);
    } else {
      order = 'asc';
      orderCol = orderBy;
    }
  }

  return (
    <TableHead>
      <TableRow>
      {multiSelect && (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={selected && selected === 'part'}
            checked={selected && selected === 'all'}
            onChange={onSelectAllClick}
          />
        </TableCell>
      )}
      {columns.map((col) => (
        <TableCell
          key={col.name}
          align={col.numeric ? 'right' : 'center'}
          padding={col.noPadding ? 'none' : 'normal'}
          sortDirection={orderCol === col.name ? order : false}
        >
        {col.sortable ? (
          <TableSortLabel
            active={orderCol === col.name}
            direction={orderCol === col.name ? order : 'asc'}
            onClick={(ev) => onRequestSort(ev, col.name)}
          >
            {col.label}
          </TableSortLabel>
        ) : (
          col.label
        )}
        </TableCell>
      ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  multiSelect: PropTypes.bool,
  onSelectAllClick: PropTypes.func,
  selected: PropTypes.oneOf(['all', 'part', 'none']),
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function loopLoadData(url, qs, cb, refresh, active) {
  if (!active || !url) return;

  if (qs) url += (url.indexOf('?') > 0 ? '&' : '?') + qs;
  const loadData = () => callApi(url).then(cb).catch(console.error);
  loadData();

  if (refresh <= 0) return;

  const itvl = setInterval(() => loadData(), refresh*1000);
  return () => clearInterval(itvl);
}

// columns structure:
// [{
//   name: 'xxx',              // corresponding to data row field
//   numeric: bool,            // whether this column is numeric
//   label: 'xxx',             // display name in table header
//   sortable: bool,           // whether this column can be sortby
//   format: (row, col) => {}, // format column data, default to row[col]
// }, ...]
//
// data structure:
// {
//   rows: [{
//     id: 3,  // each row must have the idColumn (default 'id')
//     ...
//   }, ...],
//   total: 123,  // must have if multiPage is true
//   columns: ['col1', 'col2', ...],  // only set when display dynamic columns
// }
const EnhancedTable = ({columnSettings, displayColumns, idColumn, dataUrl, refresh, multiPage, dense, defaultOrderBy, onDataChange, multiSelect, onSelectedChange, styles, ...others}) => {
  const docVisible = useVisibilityChange();
  const [orderBy, setOrderBy] = useState(defaultOrderBy || '');
  const [selected, setSelected] = useState([]);
  const [pagination, setPagination] = useState([0, 50]);
  const [data, setData] = useState({rows:[]});

  const finalStyle = { ...defaultStyle, ...styles };
  const {rows, total, columns: dynCols} = data || {};
  const nrows = rows.length;

  let qs = '';
  // only set pagination and order in url if is multi-page
  if (multiPage) {
    qs = `page=${pagination[0]}&limit=${pagination[1]}`;
    if (orderBy) qs += `&order=${orderBy}`;
  }

  let active = docVisible;
  // always active if not auto-refresh
  if (refresh <= 0)
    active = true;

  // reset pagination if data url changed
  useEffect(() => void(setPagination(prev => [0, prev[1]])), [dataUrl]);
  // load the data once or repeating
  useEffect(() => loopLoadData(dataUrl, qs, setData, refresh, active), [dataUrl, qs, refresh, active]);
  // caution: onDataChange should be wrapped by useCallback when using
  useEffect(() => void(onDataChange?.(data)), [data, onDataChange]);

  const handlePageChange = useCallback((_, page) => setPagination(prev => [page, prev[1]]), []);
  const handleRowsPerPageChange = useCallback((ev) => setPagination(() => [0, ev.target.value]), []);

  const handleRequestSort = useCallback((_ev, col) => {
    const order = orderBy === '-'+col ? '' : '-';
    setOrderBy(order + col);
  }, [orderBy]);

  const handleSelectAllClick = useCallback((ev) => {
    const newSelected = ev.target.checked ? rows.map((n) => n[idColumn]) : [];
    setSelected(newSelected);
    onSelectedChange && onSelectedChange(newSelected);
  }, [rows, idColumn, onSelectedChange]);

  const handleSelect = useCallback((ev) => setSelected(prev => {
    const t = ev.target, v = t.value;
    const next = [...prev];
    const i = next.indexOf(v);
    if (t.checked) {
      i === -1 && next.push(v);
    } else {
      i >= 0 && next.splice(i, 1);
    }
    onSelectedChange && onSelectedChange(next);
    return next;
  }), [onSelectedChange]);

  const columns = useMemo(() => (dynCols || displayColumns || []).map((name) => {
    const col = {name, ...columnSettings[name]};
    col.format ??= defaultFormat;
    col.label ??= formatFieldName(name);
    return col;
  }), [columnSettings, displayColumns, dynCols]);

  const visibleRows = useMemo(
    () => multiPage ? rows : [...rows].sort(getComparator(orderBy)),
    [rows, multiPage, orderBy]);

  if (!columns.length) {
    return (<Box>No data</Box>);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer sx={{overflowX: "initial"}}>
        <Table
          sx={finalStyle}
          size={dense ? 'small' : 'medium'}
          {...others}
        >
          <EnhancedTableHead
            columns={columns}
            multiSelect={!!multiSelect}
            selected={selected?.length ? (selected.length === rows.length ? 'all' : 'part') : 'none'}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
          {!nrows && (
            <TableRow><TableCell colSpan={columns.length + (multiSelect|0)} sx={{textAlign: 'center'}}>No data</TableCell></TableRow>
          )}
          {visibleRows.map((row) => (
            <TableRow hover key={row[idColumn]}>
            {multiSelect && (
              <TableCell padding="checkbox">
                <Checkbox
                  value={row[idColumn]}
                  size={dense ? 'small' : 'medium'}
                  checked={selected.includes(row[idColumn])}
                  onChange={handleSelect}
                />
              </TableCell>
            )}
            {columns.map(col => (
              <TableCell key={col.name} align={col.numeric ? 'right' : 'center'}>
                {col.format(row, col.name)}
              </TableCell>
            ))}
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
    {!!multiPage && (
      <TablePagination
        component="div"
        count={total || 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={pagination[0]}
        rowsPerPage={pagination[1]}
        rowsPerPageOptions={[30, 50, 100]}
      />
    )}
    </Box>
  );
};

EnhancedTable.propTypes = {
  columnSettings: PropTypes.object,
  displayColumns: PropTypes.arrayOf(PropTypes.string),
  idColumn: PropTypes.string,
  dataUrl: PropTypes.string,
  // seconds to auto refresh data if set
  // can also set a different negative number to reload data once
  refresh: PropTypes.number,
  multiPage: PropTypes.bool,
  dense: PropTypes.bool,
  defaultOrderBy: PropTypes.string,
  onDataChange: PropTypes.func,
  multiSelect: PropTypes.bool,
  onSelectedChange: PropTypes.func,
  styles: PropTypes.object,
};

EnhancedTable.defaultProps = {
  columnSettings: {},
  idColumn: 'id',
  refresh: 0,
  dense: true,
};

export default EnhancedTable;
