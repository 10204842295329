import React, { useState, useEffect, useCallback } from 'react';
import {
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';

import { useSession } from 'store';
import { Page, EnhancedTable } from 'components';
import { DeviceInspect } from './components';

const formatDelay = (row, col) => {
  const val = row[col];
  const color = val > 0 ? 'red' : 'inherit';
  return (<span style={{color}}>{val === 0 ? '--' : val.toFixed(2)}</span>);
};
const formatRate = (row, col) => {
  const val = row[col];
  return val === 0 ? '--' : val.toFixed(4);
}
const formatProgress = (row, _col) =>
  (100*row.progress).toFixed(2) + '% / ' + (100*row.expect_progress).toFixed(2) + '%';

const fcidStyles = {padding: '0 4px', cursor: 'pointer'};
let setDialogFcid;
const formatFcid = (row, col) => (
  <span>{row[col]}
    <span style={fcidStyles} onClick={() => setDialogFcid?.(row[col])}>📱</span>
  </span>
);

const columnSettings = {
  id: { label: 'ID', numeric: true, sortable: true },
  fc_id: { label: 'FcID', format: formatFcid },
  name: {},
  day_imps: { label: 'Plan Imps', numeric: true, sortable: true },
  ctr: { label: 'CTR (%)' },
  imp_rate: { numeric: true, sortable: true, format: formatRate },
  imp_delay: { label: 'Imp Delay (s)', numeric: true, format: formatDelay },
  clk_rate: { numeric: true, sortable: true, format: formatRate },
  clk_delay: { label: 'Clk Delay (s)', numeric: true, format: formatDelay },
  progress: { format: formatProgress },
};
const displayColumns = Object.keys(columnSettings);

const System = () => {
  const session = useSession((state) => state.session);
  const isAdmin = session.role === 'admin';

  const [accu, setAccuData] = useState([0, 0, 0, 0]);  // total, imps, clks, delayed
  const [fcid, setFcid] = useState(0);

  useEffect(() => void(setDialogFcid = setFcid), []);

  const handleDataChange = useCallback((data) => {
    let total = 0, totImps = 0, totClks = 0, delayed = 0;
    data?.rows.forEach(row => {
      total += 1;
      if (row.imp_delay > 0 || row.clk_delay > 0) delayed += 1;
      totImps += row.imp_rate;
      totClks += row.clk_rate;
    });
    setAccuData([total, totImps, totClks, delayed]);
  }, []);
  const closeDeviceModal = useCallback(() => setFcid(0), []);

  if (!isAdmin) {
    return (
      <Page title="Goodday">
        <Typography variant="h6" component="div">Have a nice day!</Typography>
      </Page>
    );
  }
  return (
    <Page title="Systems">
      <Toolbar sx={{mt: -1}}>
        <Typography variant="h6" component="div" sx={{
          '& span': {
            padding: '3px 5px',
            marginRight: '10px',
            backgroundColor: '#f0f0f0',
            fontWeight: 550,
            borderRadius: '5px',
          }
        }}>
          Total: <span>{accu[0]}</span>
          Imps: <span>{accu[1].toFixed(2)}</span>
          Clicks: <span>{accu[2].toFixed(2)}</span>
          Delayed: <span>{accu[3]}</span>
        </Typography>
      </Toolbar>
      <Paper sx={{p: 1, pb: 0, m: 2, mt: -1}}>
        <EnhancedTable
          stickyHeader
          columnSettings={columnSettings}
          displayColumns={displayColumns}
          dataUrl="/systems"
          refresh={60}
          dense={true}
          defaultOrderBy="-id"
          onDataChange={handleDataChange}
        />
      </Paper>
      <DeviceInspect fcid={fcid} onClose={closeDeviceModal} />
    </Page>
  );
};

export default System;
