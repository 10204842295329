import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { useVisibilityChange, useDebounce } from "@uidotdev/usehooks";
import {
  Button,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Input,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AdsClick as TrackIcon,
  BarChart as ReportIcon,
  CalendarViewMonthOutlined as CalendarIcon,
  ContentCopy as CopyIcon,
  DeleteSweep as CleanupIcon,
  EditOutlined as EditIcon,
  MoreVert as MoreVertIcon,
  Pause as PauseIcon,
  PhoneAndroid as DeviceIcon,
  PlayArrow as PlayIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

import { useSession, useMisc } from 'store';
import { Page } from 'components';
import { Overview, JobState, LinkView, JobReport, DeviceCheck, TrackList } from './components';
import { callApi } from 'utils';
import { trackers, trackerIcons } from 'consts';

const TrackData = ({percent, icon}) => (
  <Typography sx={{display: 'flex', alignItems: 'center', fontSize: 'inherit'}}>
    <img style={{height: '18px', width: '18px', marginRight: '3px'}} src={icon} alt="agent" />
    {percent}%
  </Typography>
);

const columnHelper = {
  data: {},
  ymd: '',
  setData(misc, activeJobs) {
    this.data = {misc, activeJobs};
    this.ymd = dayjs().tz().format('YYYYMMDD');
  },
  dayPlan(job) {
    if (this.data.activeJobs?.includes(job)) {
      const imps = isNaN(job.daily_imps[this.ymd]) ? job.daily_imps.default : job.daily_imps[this.ymd];
      if (imps > 0) {
        return (
          <Typography component="span" sx={{fontSize: 'inherit'}}>
            {imps}
            {job.overrun > 0 && (<span style={{marginLeft:'3px',color:'rgb(159,159,159)'}}>+{job.overrun}%</span>)}
          </Typography>
        );
      }
    }
    return '--';
  },
  dayparts(job) {
    return (job.dayparts[this.ymd] || job.dayparts.default || ["00:00", "23:59"]).join('~');
  },
  miscData(cat, val) {
    return this.data.misc?.[cat]?.[val];
  },
};

function reportData(rt, field) {
  if (!rt) return '--';
  if (field === 'ctr')
    return (100*rt.clicks/rt.imps).toFixed(1);
  else if (field === 'landing')
    return rt.clicks > 0 ? (100*rt.landing/rt.clicks).toFixed(1) + '%' : '--';
  else if (field === 'progress') {
    const ps = rt.progress;
    if (!ps?.[1]) return '--';
    const color = ps[1]-ps[0] >= 0.15 ? 'red' : 'inherit';
    return (<span style={{color}}>{ps.map(p => (100*p).toFixed(1) + '%').join(' / ')}</span>);
  } else if (field === 'track') {
    const t = trackers.map((name, i) => [rt.tracker_cnt[i], trackerIcons[name]]).find(a => a[0] > 0);
    if (!t)
      return '--';
    const p = (100*t[0]/rt.landing).toFixed(1);
    if (p === '0.0')
      return '--';
    return (<TrackData percent={p} icon={t[1]} />);
  }
  return rt[field] || 0;
}

const columns = {
  id: {
    name: 'ID',
    value: row => row.id,
  },
  name: {
    name: 'Name',
    value: row => row.extra ? (<Tooltip title={row.extra.replaceAll('@@',' / ')}><span>{row.name}</span></Tooltip>) : row.name,
  },
  type: {
    name: 'Type',
    value: row => row.ctr === 100 ? 'Click' : (row.ctr ? 'Mixed' : 'Imp'),
  },
  schedule: {
    name: 'Start/End Date',
    value: row => row.start_date + ' ~ ' + row.end_date,
  },
  dayparts: {
    name: 'Daypart',
    value: row => columnHelper.dayparts(row),
  },
  url: {
    name: 'Landing Url',
    value: row => (<LinkView destUrl={row.dest_url} trackers={row.trackers} />),
  },
  traffic: {
    name: 'Traffic',
    value: row => columnHelper.miscData('traffic_curve', row.traffic_curve),
  },
  plan: {
    name: 'Plan',
    value: row => columnHelper.dayPlan(row),
  },
  imps: {
    name: 'Imps',
    value: (_, rt) => reportData(rt, 'imps'),
  },
  clicks: {
    name: 'Clicks',
    value: (_, rt) => reportData(rt, 'clicks'),
  },
  ctr: {
    name: 'CTR',
    value: (_, rt) => reportData(rt, 'ctr'),
  },
  landing: {
    name: 'Landing',
    value: (_, rt) => reportData(rt, 'landing'),
  },
  progress: {
    name: 'Progress',
    value: (_, rt) => reportData(rt, 'progress'),
  },
  track: {
    name: 'Track',
    value: (_, rt) => reportData(rt, 'track'),
  },
};

function reload(flt, q, pager, cb) {
  callApi(`/jobs?status=${flt.status}&org=${flt.org}&q=${encodeURIComponent(q)}&sort=-id&${pager}`)
    .then(cb).catch(console.error);
}

function loopGetRtReport(active, cb, delay) {
  if (!active)
    return;

  const getReport = () => callApi(`/reports/realtime`).then(rows =>
    cb(Object.fromEntries(rows.map(row => [row.ad, row])))
  ).catch(console.error);

  getReport();
  const itvl = setInterval(() => getReport(), delay);
  return () => clearInterval(itvl);
}

function download(url, basename) {
  // https://stackoverflow.com/questions/695151/data-protocol-url-size-limitations
  callApi(url)
    .then(resp => fetch(`data:application/octet-stream;base64,${resp.b64}`))
    .then(resp => resp.blob())
    .then(blob => {
      const fn = basename + '-' + dayjs().tz().format('MMDD_HHmmss') + '.xlsx';
      // create blob link to download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', fn);
      document.body.appendChild(a);
      a.click();
      setTimeout(() => { a.parentNode.removeChild(a); window.URL.revokeObjectURL(url); }, 200);
    })
    .catch(console.error);
}

const JobRow = memo(({row, report, cols, isWarren, selected, onSelect, onSuspend, onShowJobState, onOpenMoreOps}) => {
  const id = row.id, suspend = row.suspend;
  //console.log(`render row ${id}`);

  return (
    <TableRow hover data-rowid={id}>
    {selected >= 0 && (
      <TableCell>
        <Checkbox value={id} size="small" sx={{p:'3px'}} checked={selected > 0} onChange={onSelect} />
      </TableCell>
    )}
      {cols.map(col => (<TableCell key={col}>{columns[col].value(row, report)}</TableCell>))}
      <TableCell sx={{ whiteSpace: 'nowrap', '& .MuiButtonBase-root': {p: '3px'} }}>
        <IconButton onClick={() => onSuspend(!suspend, id)} size="small">
          {suspend ? (<PlayIcon fontSize="inherit" />) : (<PauseIcon fontSize="inherit" />)}
        </IconButton>
      {isWarren && [
        <IconButton component={NavLink} to={`/jobs/${id}/edit`} size="small" key="edit">
          <EditIcon fontSize="inherit" />
        </IconButton>,
        <IconButton component={NavLink} to={`/jobs/new?copy=${id}`} size="small" key="copy">
          <CopyIcon fontSize="inherit" />
        </IconButton>
      ]}
        <IconButton onClick={() => onShowJobState(id)} size="small">
          <CalendarIcon fontSize="inherit" />
        </IconButton>
        <IconButton id={'jobops-'+id} onClick={onOpenMoreOps} size="small">
          <MoreVertIcon fontSize="inherit" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

// enum for all model types
const ModalTypes = Object.freeze({
  OVERVIEW: 1,
  JOB_STATE: 2,
  SUSPEND_JOBS: 3,
  EXPORT_ALL: 4,
  PAGE_ACTION: 5,
  DAILY_REPORT: 6,
  TRACK_STATS: 7,
  DEVICE: 8,
  CLEANUP: 9,
});

const adminCols = ['id', 'name', 'type', 'schedule', 'dayparts', 'url', 'traffic', 'plan', 'imps', 'clicks', 'ctr', 'landing', 'progress'];
const otherCols = ['id', 'name', 'type', 'schedule', 'dayparts', 'traffic', 'plan', 'imps', 'clicks', 'ctr', 'landing', 'progress'];

const JobList = () => {
  const documentVisible = useVisibilityChange();
  const session = useSession((state) => state.session);
  const misc = useMisc((state) => state.data);
  const [pagination, setPagination] = useState([0, 50]);
  const [list, setList] = useState({rows:[],total:0});
  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState({status: 'active', org: ''});
  const [search, setSearch] = useState('');
  const [reportDays, setReportDays] = useState(1);
  const [pageAction, setPageAction] = useState('');
  const [modal, setModal] = useState(null);
  const [report, setReport] = useState({}); // realtime report
  const [moreOpsEl, setMoreOpsEl] = useState(null);
  const q = useDebounce(search, 1500);

  const rows = list.rows;
  const isAdmin = session.role === 'admin';
  const isWarren = session.uid === 2;

  // do not use 'pagination' as deps in useEffect since it might be changed in handleFilter
  const pager = `page=${pagination[0]}&limit=${pagination[1]}`;
  useEffect(() => {
    reload(filter, q, pager, setList);
    setSelected([]);
  }, [filter, q, pager]);

  const handleSelect = useCallback((ev) => setSelected(prev => {
    const t = ev.target, v = Number(t.value);
    const next = [...prev];
    const i = next.indexOf(v);
    if (t.checked) {
      i === -1 && next.push(v);
    } else {
      i >= 0 && next.splice(i, 1);
    }
    return next;
  }), []);

  const exportSelected = (target) => {
    if (!selected.length) return;
    const ids = selected.join(',');
    let url;
    if (target === 'jobs') {
      url = `/jobs/export?ids=${ids}`;
    } else if (target.startsWith('report-')) {
      // report-hourly/report-region
      url = `/reports/export?type=${target.substring(7)}&ids=${ids}`;
    }
    download(url, target);
    setSelected([]);
  };

  const handleFilter = useCallback((ev) => {
    const t = ev.target;
    if (t.name === 'search') {
      // set search separately since we need to use debounce on search input
      setSearch(t.value);
    } else {
      setFilter(prev => ({...prev, [t.name]: t.value}));
    }
    setPagination(prev => [0, prev[1]]);
  }, []);
  const handleStatus = useCallback((ev, val) => {
    // ToggleButtonGroup do not have a name property
    ev.target.name = 'status';
    ev.target.value = val;
    handleFilter(ev);
  }, [handleFilter]);
  const handlePageChange = useCallback((_, page) => setPagination(prev => [page, prev[1]]), []);
  const handleRowsPerPageChange = useCallback((ev) => setPagination(prev => [prev[0], ev.target.value]), []);
  const reloadJobs = useCallback(() => reload(filter, q, pager, setList), [filter, q, pager]);

  const handleOpenMoreOps = useCallback((ev) => setMoreOpsEl(ev.currentTarget), []);
  const handleCloseMoreOps = useCallback(() => setMoreOpsEl(null), []);
  const moreopsElId = moreOpsEl?.id || '';
  const moreopsJob = moreopsElId.startsWith('jobops-') ? rows.find(row => row.id === Number(moreopsElId.substring(7))) : null;

  const clickMenu = (op) => setModal([op, moreopsJob]);

  const openOverview = useCallback(() => setModal([ModalTypes.OVERVIEW]), []);
  const showJobState = useCallback((id) => setModal([ModalTypes.JOB_STATE, id]), []);
  const confirmSuspend = useCallback((suspend, id) => setModal([ModalTypes.SUSPEND_JOBS, [suspend, id]]), []);
  const selectExportDays = useCallback(() => setModal([ModalTypes.EXPORT_ALL]), []);
  const batchSetPageAction = useCallback(() => setModal([ModalTypes.PAGE_ACTION]), []);
  const closeModal = useCallback(() => setModal(null), []);
  const handleChangeReportDays = useCallback((ev) => setReportDays(ev.target.value), []);
  const handleSetPageact = useCallback((ev) => setPageAction(ev.target.value), []);

  let modalType, modalData, dialogContent;
  if (modal) {
    const [type, args] = modal;
    modalType = type;
    const job = typeof args === 'number' ? rows.find(row => row.id === args) : args;
    if (type === ModalTypes.JOB_STATE || type === ModalTypes.TRACK_STATS) {
      modalData = { job, stats: report[job?.id] };
    } else if (type === ModalTypes.DAILY_REPORT || type === ModalTypes.DEVICE) {
      modalData = job;
    } else if (type === ModalTypes.SUSPEND_JOBS) {
      const suspend = args[0];
      const jobIds = args[1] ? [args[1]] : selected;
      const jobs = rows.filter(row => row.suspend !== suspend && jobIds.includes(row.id));
      const l = jobs.length;
      if (l === 0) {
        setModal(null);
      } else {
        let txt = (suspend ? 'Suspend ' : 'Continue ') +
          (l === 1 ? `job ${jobs[0].id}: ${jobs[0].name}?` : `selected ${l} jobs?`);
        dialogContent = (<DialogContentText>{txt}</DialogContentText>);
        modalData = { suspend, ids: jobs.map(job => job.id) };
      }
    } else if (type === ModalTypes.EXPORT_ALL) {
      dialogContent = (
        <Box>
          <span style={{marginRight: '5px'}}>Export last</span>
          <Select value={reportDays} size="small" onChange={handleChangeReportDays}>
            {[1,2,3,4,5,6,7].map((n) => (<MenuItem value={n} key={n}>{n} days</MenuItem>))}
          </Select>
        </Box>
      );
    } else if (type === ModalTypes.PAGE_ACTION) {
      dialogContent = (
        <Box>
          <span style={{marginRight: '5px'}}>Page Action: </span>
          <Input
            sx={{ml: '8px', p: 0, width: 150}}
            value={pageAction}
            size="small"
            onChange={handleSetPageact}
          />
        </Box>
      );
    } else if (type === ModalTypes.CLEANUP) {
      modalData = job.id;
      dialogContent = (<DialogContentText>{`Cleanup job ${job.id}: ${job.name}?`}</DialogContentText>);
    }
  }

  const confirmDialog = () => {
    if (modalType === ModalTypes.EXPORT_ALL) {
      const url = `/reports/export?type=allindays&days=${reportDays}`;
      download(url, `last-${reportDays}-days`);
      setModal(null);
    } else if (modalType === ModalTypes.CLEANUP) {
      callApi(`/jobs/${modalData}/cleanup`).then((res) => {
        setModal(null);
        console.log(res);
      }).catch(console.error);
    } else {
      let ids, data;
      if (modalType === ModalTypes.SUSPEND_JOBS) {
        ids = modalData?.ids;
        data = {suspend: modalData?.suspend};
      } else if (modalType === ModalTypes.PAGE_ACTION) {
        ids = selected;
        data = {ant_cfg: pageAction};
      } else {
        return;
      }
      if (!ids?.length) return;
      const url = `/jobs/batch?ids=${ids.join(',')}`;
      callApi(url, 'PUT', data).then(() => {
        setModal(null);
        setPageAction('');
        setSelected([]);
        reloadJobs();
      }).catch(console.error);
    }
  };

  const today = dayjs().tz().format('YYYY-MM-DD');
  const activeJobs = useMemo(
    () => rows.filter(job => job.start_date <= today && today <= job.end_date),
    [rows, today]);

  const updateRtReport = activeJobs.length > 0 && documentVisible;
  useEffect(() => loopGetRtReport(updateRtReport, setReport, 60_000), [updateRtReport]);

  // set helper data for column value display
  columnHelper.setData(misc, activeJobs);
  const showColumns = isAdmin ? adminCols : otherCols;

  return (
    <Page title="Job List">
      <Toolbar sx={{mt: -1}}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <ToggleButtonGroup size="small" value={filter.status} exclusive onChange={handleStatus}>
            <ToggleButton value="active" sx={{padding: '5px'}}>Active</ToggleButton>
            <ToggleButton value="running" sx={{padding: '5px'}}>Running</ToggleButton>
            <ToggleButton value="all" sx={{padding: '5px'}}>All</ToggleButton>
          </ToggleButtonGroup>
          {isAdmin && (
          <Select
            name="org"
            value={filter.org}
            size="small"
            onChange={handleFilter}
            displayEmpty
            sx={{ ml: 1, '& .MuiSelect-select': {py: '6px'} }}
          >
            <MenuItem value={''}>All Orgs</MenuItem>
            {Object.entries(misc['org']).map(([val, display]) => (
            <MenuItem value={val} key={val}>{display}</MenuItem>
            ))}
          </Select>
          )}
        </Typography>
        <Input
          sx={{p: '2px', mr: 1, width: 160}}
          name="search"
          value={search}
          size="small"
          onChange={handleFilter}
          inputProps={{ style: {marginLeft: '-5px'} }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Button color="inherit" onClick={openOverview}>Overview</Button>
        <Button color="inherit" onClick={reloadJobs}>Reload</Button>
        <IconButton color="inherit" id="globalops" onClick={handleOpenMoreOps}>
          <MoreVertIcon />
        </IconButton>
      </Toolbar>
      <Paper sx={{p: 1, pb: 0, m: 2, mt: -1}}>
        <TableContainer sx={{overflowX: "initial"}}>
          <Table stickyHeader sx={{
            minWidth: 1050,
            '& th,td': {
              padding: 1,
              textAlign: 'center',
              whiteSpace: 'pre-line',
            }
          }}>
            <TableHead>
              <TableRow>
                {filter.status !== 'running' && (<TableCell></TableCell>)}
                {showColumns.map(col => (<TableCell key={col}>{columns[col].name}</TableCell>))}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
              <JobRow
                key={row.id}
                row={row}
                report={report?.[row.id]}
                isWarren={isWarren}
                cols={showColumns}
                selected={filter.status === 'running' ? -1 : (selected.includes(row.id)|0)}
                onSelect={handleSelect}
                onSuspend={confirmSuspend}
                onShowJobState={showJobState}
                onOpenMoreOps={handleOpenMoreOps}
              />
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={list.total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={pagination[0]}
          rowsPerPage={pagination[1]}
          rowsPerPageOptions={[50, 100, 200]}
        />
      </Paper>
      <Menu
        id="globalops-menu"
        anchorEl={moreOpsEl}
        open={moreopsElId === 'globalops'}
        onClose={handleCloseMoreOps}
        onClick={handleCloseMoreOps}
      >
        {isAdmin && [
        <MenuItem component={NavLink} to={`/jobs/new`} key="add">Add Job</MenuItem>,
        <MenuItem component={NavLink} to={`/jobs/batchadd`} key="batch">Batch Add Jobs</MenuItem>,
        <MenuItem disabled={selected.length===0} onClick={batchSetPageAction} key="pageact">Set Page Action</MenuItem>
        ]}
        <MenuItem disabled={selected.length===0} onClick={() => confirmSuspend(true, 0)}>Pause Selected Jobs</MenuItem>
        <MenuItem disabled={selected.length===0} onClick={() => confirmSuspend(false, 0)}>Resume Selected Jobs</MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem disabled={selected.length===0} onClick={() => exportSelected('jobs')}>Export Selected Jobs</MenuItem>
        <MenuItem disabled={selected.length===0} onClick={() => exportSelected('report-hourly')}>Export Hourly Report</MenuItem>
        <MenuItem disabled={selected.length===0} onClick={() => exportSelected('report-region')}>Export Region Report</MenuItem>
        <MenuItem onClick={selectExportDays}>Export All Report</MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem component={NavLink} to={`/reports?dim=jobdaily`} target="_blank">View History Report</MenuItem>
        <MenuItem component={NavLink} to={`/jobs/changelist`} target="_blank">View Jobs Changelist</MenuItem>
      </Menu>
      <Menu
        id="jobops-menu"
        anchorEl={moreOpsEl}
        open={Boolean(moreopsJob)}
        onClose={handleCloseMoreOps}
        onClick={handleCloseMoreOps}
      >
        {isAdmin && [
        <MenuItem component={NavLink} to={`/jobs/${moreopsJob?.id}/edit`} key="edit">
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>,
        <MenuItem component={NavLink} to={`/jobs/new?copy=${moreopsJob?.id}`} key="copy">
          <ListItemIcon>
            <CopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
        </MenuItem>,
        ]}
        <MenuItem onClick={() => clickMenu(ModalTypes.DAILY_REPORT)}>
          <ListItemIcon>
            <ReportIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Daily Report</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => clickMenu(ModalTypes.TRACK_STATS)}>
          <ListItemIcon>
            <TrackIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Tracks</ListItemText>
        </MenuItem>
        {moreopsJob?.prepared > 0 && (
        <MenuItem onClick={() => clickMenu(ModalTypes.DEVICE)}>
          <ListItemIcon>
            <DeviceIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Devices</ListItemText>
        </MenuItem>
        )}
        {isAdmin && moreopsJob?.id === moreopsJob?.fc_id && (
        <MenuItem onClick={() => clickMenu(ModalTypes.CLEANUP)}>
          <ListItemIcon>
            <CleanupIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cleanup</ListItemText>
        </MenuItem>
        )}
      </Menu>
      <Overview open={modalType === ModalTypes.OVERVIEW} onClose={closeModal} />
      <JobState state={modalType === ModalTypes.JOB_STATE ? modalData : null} onClose={closeModal} />
      <JobReport job={modalType === ModalTypes.DAILY_REPORT ? modalData : null} onClose={closeModal} />
      <DeviceCheck job={modalType === ModalTypes.DEVICE ? modalData : null} onClose={closeModal} />
      <TrackList data={modalType === ModalTypes.TRACK_STATS ? modalData : null} onClose={closeModal} />
    {Boolean(dialogContent) && (
      <Dialog open={true} onClose={closeModal}>
        <DialogContent>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button onClick={confirmDialog}>Confirm</Button>
        </DialogActions>
      </Dialog>
    )}
    </Page>
  );
};

export default JobList;
