import React from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';

import { Page, EnhancedTable } from 'components';

const formatDateTime = (row, col) => dayjs(row[col]).format('YYYY-MM-DD HH:mm:ss');
const formatContent = (row, col) => (
  <Box sx={{
    '& > *:not(:last-child)::after': { content: '", "' },
  }}>
  {Object.entries(row[col] || {}).map(([k, v]) => Array.isArray(v) && v.length === 2 ?
    <Tooltip key={k} title={v.map(a => a || '""').join(' → ')}>{k}</Tooltip>
    :
    <Typography key={k} component="span" sx={{fontSize: 'inherit'}}>{k}</Typography>
  )}
  </Box>
);

const columnSettings = {
  date: { format: formatDateTime },
  content: { format: formatContent },
};
const displayColumns = ['job', 'name', 'date', 'user', 'content'];

const Changelist = () => {
  return (
    <Page title='Job Changelist'>
      <Paper sx={{p: 1, pb: 0, m: 2, mt: 1}}>
        <EnhancedTable
          stickyHeader
          columnSettings={columnSettings}
          displayColumns={displayColumns}
          dataUrl="/jobs/changelist"
          dense={true}
          multiPage={true}
        />
      </Paper>
    </Page>
  );
};

export default Changelist;
