import React, { useState, useCallback } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { callApi } from 'utils';
import { useSession } from 'store';
import { Page } from 'components';

const IpCount = () => {
  const session = useSession((state) => state.session);
  const isAdmin = session.role === 'admin';

  const [regions, setRegions] = useState('');
  const [ctr, setCtr] = useState(0);
  const [res, setRes] = useState({});
  const [saving, setSaving] = useState(false);

  const total = res.rows?.reduce((accu, row) => accu + row.ips, 0) || 0;
  let maxPlan;
  if (total > 0) {
    // in case one ip can have 100 imps or 3 clicks:
    // x*ctr/3 + (x-x*ctr)/100 = ip_cnt      ->
    // 100*x*ctr + 3*(x-x*ctr) = 300*ip_cnt  ->
    // 97*x*ctr + 3*x = 300*ip_cnt           ->
    // x = 300*ip_cnt / (97*ctr+3)
    maxPlan = total*300/(97*ctr/100+3) |0;
  }

  const handleChange = useCallback((ev) => {
    const t = ev.target;
    if (t.name === 'regions')
      setRegions(t.value);
    else if (t.name === 'ctr')
      setCtr(t.value|0);
  }, []);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (saving) {
      return false;
    }
    setSaving(true);

    const data = { regions };
    callApi('/misc/ipcount', 'POST', data)
      .then((res) => {
        setRes(res);
        setSaving(false);
      }).catch(() => setSaving(false));
  };

  if (!isAdmin) {
    return (
      <Page title="Goodday">
        <Typography variant="h6" component="div">Have a nice day!</Typography>
      </Page>
    );
  }
  return (
    <Page title="IP Count">
      <Paper sx={{p: 1, m: 2}}>
        <Grid container spacing={1} wrap="wrap" sx={{ '& .MuiTypography-subtitle1': {mb: 0} }}>
          <Grid item container xs={12}>
            <Grid item xs={2} md={1}>
              <FormLabel gutterBottom variant="subtitle1">Regions:</FormLabel>
            </Grid>
            <Grid item xs>
              <FormControl sx={{width: '100%'}}>
                <OutlinedInput
                  sx={{padding: 0, width: 600}}
                  name="regions"
                  value={regions}
                  inputProps={{ style: {padding: '8.5px 14px'} }}
                  error={Boolean(res.error)}
                  size="small"
                  onChange={handleChange}
                  multiline
                />
              {!!res.error && (
                <FormHelperText sx={{ml: 0, color: 'red'}}>{res.error}</FormHelperText>
              )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={2} md={1}>
              <FormLabel gutterBottom variant="subtitle1">CTR(%):</FormLabel>
            </Grid>
            <Grid item xs>
              <FormControl>
                <OutlinedInput
                  sx={{padding: 0, width: 140}}
                  name="ctr"
                  value={ctr}
                  size="small"
                  inputProps={{ style: {textAlign: 'right'} }}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleSubmit}
              disabled={saving}
            >
              {saving ? "Checking..." : "Check"}
            </Button>
          </Grid>
        </Grid>
      {total > 0 && (
        <>
        <Divider sx={{my: 2}} />
        <Typography component="div">
          <span style={{marginRight: '16px'}}>Total IPs: {total}</span>
          {!!maxPlan && (<span>Max Plan: {maxPlan}</span>)}
        </Typography>
        <Divider sx={{my: 2}} />
        <Table size="small" sx={{
          '& th,td': {
            textAlign: 'center',
          }
        }}>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {res.rows?.map(row => (
              <TableRow hover key={row.code}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.ips}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </>
      )}
      </Paper>
    </Page>
  );
};

export default IpCount;
