import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { Popup } from 'components';
import { trackers } from 'consts';

const supportedTrackers = [
  {
    name: 'Tink',
    valid: (s) => s?.clicks > 0,
    count: (s) => s.landing,
    stay:  (s) => Math.ceil(s.avg_stay) + 's',
    percent: (s) => (100*s.landing/s.clicks).toFixed(1) + '%',
    bounce: (_) => '--',
  },
  ...trackers.map((name, i) => ({
    name,
    valid: (s) => s?.tracker_cnt[i] > 0,
    count: (s) => s.tracker_cnt[i],
    stay:  (s) => Math.ceil(s.tracker_stay[i]) + 's',
    percent: (s) => (100*s.tracker_cnt[i]/s.clicks).toFixed(1) + '%',
    bounce: (s) => isNaN(s.tracker_bounce[i]) ? '--' : (100*s.tracker_bounce[i]/s.tracker_cnt[i]).toFixed(1) + '%',
  })),
];

const TrackerList = ({data, onClose}) => {
  if (!data)
    return null;

  const {job, stats} = data;

  return (
    <Popup
      open={Boolean(job)}
      onClose={onClose}
      title={`${job.id} - ${job.name}`}
      styles={{width: '550px'}}
    >
      <Table size="small" sx={{
        '& th,td': {
          textAlign: 'center',
        }
      }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Arrival</TableCell>
            <TableCell>Arrival Rate</TableCell>
            <TableCell>Stay</TableCell>
            <TableCell>Bounce Rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {supportedTrackers.map(tkr => (
          <TableRow key={tkr.name}>
            <TableCell>{tkr.name}</TableCell>
            <TableCell>{tkr.valid(stats) ? tkr.count(stats) : '--'}</TableCell>
            <TableCell>{tkr.valid(stats) ? tkr.percent(stats) : '--'}</TableCell>
            <TableCell>{tkr.valid(stats) ? tkr.stay(stats) : '--'}</TableCell>
            <TableCell>{tkr.valid(stats) ? tkr.bounce(stats) : '--'}</TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </Popup>
  );
};

export default TrackerList;
