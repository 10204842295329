import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { callApi } from 'utils';
import { Popup } from 'components';

const DetailPlan = ({title, rows, onClose}) => (
  <Popup
    open={true}
    onClose={onClose}
    title={title}
    styles={{width: '600px'}}
  >
    <Table size="small" sx={{
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      '& th,td': { textAlign: 'center' },
    }}>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>CTR</TableCell>
          <TableCell>Imps</TableCell>
          <TableCell>Clicks</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <TableRow hover key={row.id}>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.ctr}</TableCell>
            <TableCell>{row.imps}</TableCell>
            <TableCell>{row.clks}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Popup>
);

const DayCard = ({day, totalImps, totalClks, dayPlan}) => {
  const [detail, setDetail] = useState(false);
  const openDetail = useCallback(() => setDetail(true), []);
  const closeDetail = useCallback(() => setDetail(false), []);

  return (
    <Card sx={{
      position: 'relative',
      p: 1,
      mb: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}>
      <Box onClick={openDetail}>
        <Typography variant="caption" component="div" gutterBottom sx={{mt: '-3px'}}>{day}</Typography>
        <Typography sx={{fontSize: '14px'}}>{totalImps} / {totalClks}</Typography>
      </Box>
      {detail && (<DetailPlan title={`${day} (${totalImps} / ${totalClks})`} rows={dayPlan} onClose={closeDetail} />)}
    </Card>
  );
};

const Overview = ({open, onClose}) => {
  const [jobs, setJobs] = useState([]);

  // reload when reopen
  useEffect(() => {
    open && callApi(`/jobs/overview`).then(setJobs).catch(console.error);
  }, [open]);

  if (!open)
    return null;

  let maxEndDate = dayjs();
  jobs.forEach((job) => {
    job.start_date = dayjs(job.start_date);
    job.end_date = dayjs(job.end_date);
    job.end_date.isAfter(maxEndDate, 'day') && (maxEndDate = job.end_date);
  });

  // build every day overview from today
  const days = [];
  for (let d = dayjs(); !d.isAfter(maxEndDate, 'day'); d = d.add(1, 'day')) {
    const dt = d.format('YYYYMMDD');
    const dayPlan = [];
    let totalImps = 0, totalClks = 0;
    jobs.forEach((job) => {
      if (d.isBefore(job.start_date, 'day') || d.isAfter(job.end_date, 'day'))
        return;
      let imps = isNaN(job.daily_imps[dt]) ? job.daily_imps.default : job.daily_imps[dt];
      if (imps > 0) {
        const clks = imps*job.ctr/100 |0;
        imps -= clks;
        totalImps += imps;
        totalClks += clks;
        dayPlan.push({
          id: job.id,
          name: job.name,
          ctr: job.ctr,
          imps, clks,
        });
      }
    });
    days.push({
      day: d.format('MM-DD'),
      totalImps,
      totalClks,
      dayPlan,
    });
  }

  return (
    <Popup
      open={open}
      onClose={onClose}
      title="Schedule Overview"
      styles={{ '@media (min-width: 960px)': {width: '960px'} }}
    >
      <Grid sx={{mt: 0}} container spacing={1.5}>
      {days.map(day => (
        <Grid item lg={2} sm={4} xs={6} key={day.day}>
          <DayCard {...day} />
        </Grid>
      ))}
      </Grid>
    </Popup>
  );
};

export default Overview;
