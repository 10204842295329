import React from 'react';
import { Popup, EnhancedTable } from 'components';

const AggReport = ({agg, onClose}) => {
  if (!agg)
    return null;

  return (
    <Popup
      open={Boolean(agg)}
      onClose={onClose}
      title={'Aggregate ' + agg}
      styles={{width: 'auto'}}
    >
      <EnhancedTable
        styles={{minWidth: '400px'}}
        stickyHeader
        idColumn="date"
        dataUrl={`/reports?dim=agg-${agg}`}
        dense={true}
      />
    </Popup>
  );
};

export default AggReport;
